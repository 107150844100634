import { RouteParamName } from './app.route.params';

export const route = {
  root: {
    name: 'Root',
    path: '',
    fullPath: '/'
  },
  home: {
    name: 'Home',
    path: 'home',
    fullPath: '/home'
  },
  login: {
    name: 'Login',
    path: 'login',
    fullPath: '/login'
  },
  signUp: {
    name: 'Sign up',
    path: 'sign-up',
    fullPath: '/sign-up',
    children: {
      terms: {
        name: '약관 동의',
        path: 'terms',
        fullPath: '/sign-up/terms'
      },
      termsForInvitation: {
        name: '약관 동의',
        path: 'invitation/terms',
        fullPath: '/sign-up/invitation/terms'
      },
      signUpCompletion: {
        name: 'Sign up Completion',
        path: 'completion',
        fullPath: '/sign-up/completion',
      },
      signUpInformation: {
        name: '정보 입력',
        path: 'information',
        fullPath: '/sign-up/information'
      },
      signUpOptionalInformation: {
        name: '선택 정보 입력',
        path: 'optional-information',
        fullPath: '/sign-up/optional-information'
      },
      emailSend: {
        name: '인증 메일 발송',
        path: 'send',
        fullPath: '/sign-up/send'
      },
      emailConfirm: {
        name: '인증 메일 확인',
        path: 'confirm',
        fullPath: '/sign-up/confirm'
      },
      signUpGoogleCreation: {
        name: '구글 계정 생성',
        path: 'google-terms',
        fullPath: '/sign-up/google-terms'
      },
      selectService: {
        name: '서비스 선택',
        path: 'select-service',
        fullPath: '/sign-up/select-service'
      }
    }
  },
  products: {
    name: 'Products',
    path: 'products',
    fullPath: '/products',
  },
  modelDetail: {
    name: '모델 디테일',
    path: `products/model/:${RouteParamName.id}`,
    fullPath: MODEL_DETAIL_PATH
  },
  sdk: {
    name: 'SDK 디테일',
    path: `products/sdk/:${RouteParamName.id}`,
    fullPath: SDK_PATH
  },
  dashboard: {
    name: 'Dashboard',
    path: 'dashboard',
    fullPath: '/dashboard',
    children: {
      usageManagement: {
        name: 'Usage Management',
        path: 'usage-management',
        fullPath: '/dashboard/usage-management',
      },
      members: {
        name: 'Members',
        path: 'members',
        fullPath: '/dashboard/members',
      }
    }
  },
  newWorkspace: {
    name: 'New Workspace',
    path: 'new-workspace',
    fullPath: '/new-workspace'
  },
  workspaceProfile: {
    name: 'Workspace Profile',
    path: 'workspace-profile',
    fullPath: '/workspace-profile'
  },
  workspaceError: {
    name: 'Workspace Error',
    path: 'workspace-error',
    fullPath: '/workspace-error',
  },
  usecase: {
    name: 'usecase',
    path: 'usecase',
    fullPath: '/usecase'
  },
  pricing: {
    name: 'pricing',
    path: 'pricing',
    fullPath: '/pricing'
  },
  studioIntroducing: {
    name: 'studio',
    path: 'products/studio',
    fullPath: '/products/studio'
  },
  authentication: {
    name: 'azure sso authentication',
    path: 'authentication',
    fullPath: '/authentication',
  },
  authenticationExpired: {
    name: 'authentication expired',
    path: 'authentication-expired',
    fullPath: '/authentication-expired',
  },
  subscriptionActivate: {
    name: 'subscription activate',
    path: 'subscription-activate',
    fullPath: '/subscription-activate',
  },
  invitationAccept: {
    name: 'invitation accept',
    path: 'invitation/accept',
    fullPath: '/invitation/accept',
  }
};

export function MODEL_DETAIL_PATH(id: string): string {
  return `/products/model/${id}`;
}

export function SDK_PATH(id: string): string {
  return `/products/sdk/${id}`;
}
